import { Platform } from 'react-native';
import styled from 'styled-components';

import Text, { Props as TextProps } from '../Text';
import { Color, TextStyle, ThemeProps } from '../theme';
import { platformSpecific, styledWithoutProps } from '../theme/helper';

interface StyledFragmentProps extends TextProps, ThemeProps {
  valid?: boolean;
}

function fragmentColor({ valid, theme }: StyledFragmentProps): string {
  if (valid === undefined || valid === null) {
    return theme.color[Color.GRAY_500];
  }

  return theme.color[valid ? Color.SUCCESS : Color.DANGER];
}

export const Label = styled(Text)``;

Label.defaultProps = {
  styleKey: Platform.OS === 'web' ? TextStyle.BODY_3 : TextStyle.BODY_2,
  color: Color.GRAY_500,
};

export const StyledFragment = styledWithoutProps<StyledFragmentProps>(
  Text,
  'valid',
)`
  ${platformSpecific.web`
    font-size: inherit;
  `}
  color: ${fragmentColor};
`;

StyledFragment.defaultProps = {
  styleKey: Platform.OS === 'web' ? TextStyle.BODY_3 : TextStyle.BODY_2,
};
