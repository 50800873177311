import { Platform, View, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components';

import { containerWidth } from '../dimension.helper';
import { em } from '../theme';
import { platformSpecific } from '../theme/helper';

export const Container = styled(View)`
  width: ${containerWidth};
`;

export const FloatingSymbolContainer = styled(View)`
  z-index: 10;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: ${em(0.5)};
  right: ${em(0.5)};
  width: ${em(2)};
  height: ${em(2)};

  ${platformSpecific.web`
    top: 1em;
    right: 1em;
    width: 1em;
    height: 1em;
  `}
`;

// TouchableWithoutFeedback has a bug which makes not consider styles hence we use TouchableOpacity with activeOpacity=1
// https://github.com/facebook/react-native/issues/18257
export const SymbolTouchableContainer = styled(TouchableOpacity)`
  ${platformSpecific.mobile`
    width: ${em(2)};
    height: ${em(2)};
    justify-content: center;
    align-items: center;
  `};

  ${Platform.OS === 'web' &&
    css`
      cursor: pointer;
    `}
`;
SymbolTouchableContainer.defaultProps = {
  activeOpacity: 1,
};
