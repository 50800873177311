import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import React, { Component, ReactElement, Ref, forwardRef } from 'react';
import {
  Animated,
  NativeSyntheticEvent,
  Platform,
  TextInputFocusEventData,
  TextInputProps,
  TextInput as NativeTextInput,
} from 'react-native';
import { withTheme } from 'styled-components';
import { TextInputMasked } from 'react-native-masked-text';

import { Sizeable } from '../dimension.helper';
import { Color, Theme } from '../theme';
import { animatedTextStyle } from './helper';
import {
  Container,
  FloatingPlaceHolder,
  HelperContainer,
  HelperText,
  StyledMaskTextInput,
  StyledTextInput,
} from './styledComponents';
import { MaskProps } from './typings';

export interface Props extends Sizeable, TextInputProps {
  theme?: Theme;
  floatingPlaceholder?: boolean;
  helper?: string | ReactElement;
  onError?: boolean;
  innerReference?: Ref<NativeTextInput | TextInputMasked>;
  maskProps?: MaskProps;
  withLargeText?: boolean;
  borderLess?: boolean;
}

type State = {
  focused: boolean;
};

class TextInput extends Component<Props, State> {
  private animatedIsFocused: Animated.Value;

  constructor(props: Props) {
    super(props);
    this.state = {
      focused: !!props.autoFocus,
    };
  }

  UNSAFE_componentWillMount(): void {
    this.animatedIsFocused = new Animated.Value(
      this.state.focused || !isEmpty(this.props.value) ? 1 : 0,
    );
  }

  componentDidUpdate(): void {
    Animated.timing(this.animatedIsFocused, {
      toValue: this.state.focused || !isEmpty(this.props.value) ? 1 : 0,
      duration: 200,
      isInteraction: false,
      useNativeDriver: false,
    }).start();
  }

  render(): ReactElement {
    const {
      theme,
      width,
      placeholder,
      helper,
      fullWidth = false,
      floatingPlaceholder = Platform.OS === 'android',
      onError = false,
      withLargeText = false,
      borderLess = false,
      innerReference,
      maskProps,
      placeholderTextColor,
      ...textInputProps
    } = this.props;
    const { focused } = this.state;
    const Input = maskProps ? StyledMaskTextInput : StyledTextInput;

    return (
      <Container width={width} fullWidth={fullWidth}>
        {floatingPlaceholder && placeholder && (
          <FloatingPlaceHolder
            style={animatedTextStyle(
              this.animatedIsFocused,
              focused,
              theme,
              placeholderTextColor,
            )}
            isOnTop={focused || !isEmpty(this.props.value)}
          >
            {placeholder}
          </FloatingPlaceHolder>
        )}

        <Input
          {...textInputProps}
          {...maskProps}
          withLargeText={withLargeText}
          innerReference={innerReference}
          floatingPlaceholder={floatingPlaceholder}
          onBottom={floatingPlaceholder && !isEmpty(placeholder)}
          placeholder={floatingPlaceholder ? undefined : placeholder}
          placeholderTextColor={
            placeholderTextColor || (theme && theme.color[Color.GRAY_300])
          }
          maxFontSizeMultiplier={theme && theme.maxFontSizeMultiplier}
          focused={focused}
          onError={onError}
          borderLess={borderLess}
          keyboardType={Platform.select({
            web: 'default',
            default: textInputProps.keyboardType,
          })}
          onFocus={(
            event: NativeSyntheticEvent<TextInputFocusEventData>,
          ): void => {
            if (
              isUndefined(textInputProps.editable) ||
              textInputProps.editable
            ) {
              this.setState({ focused: true });
            }
            if (textInputProps.onFocus) textInputProps.onFocus(event);
          }}
          onBlur={(
            event: NativeSyntheticEvent<TextInputFocusEventData>,
          ): void => {
            this.setState({ focused: false });
            if (textInputProps.onBlur) textInputProps.onBlur(event);
          }}
        />

        {helper && (
          <HelperContainer>
            {isString(helper) ? <HelperText>{helper}</HelperText> : helper}
          </HelperContainer>
        )}
      </Container>
    );
  }
}

export default withTheme(
  forwardRef(
    (
      props: Props,
      ref?: Ref<NativeTextInput | TextInputMasked>,
    ): ReactElement => <TextInput {...props} innerReference={ref} />,
  ),
);
