import { MessageDescriptor } from 'react-intl';
import isLength from 'validator/lib/isLength';

import { Rule } from './index';

const MIN_CHARACTER_LENGTH = 8;
const HAS_LOWER_REGEX = /[a-zà-ùü/]/;
const HAS_UPPER_REGEX = /[A-ZÀ-ÙÜ]/;
const HAS_NUMBER_REGEX = /[0-9]/;
const HAS_SPECIAL_REGEX = /[^\wÀ-ü]/;

export const defaultMessageProps: MessageDescriptor = {
  id: 'customer.form.password.strengthValidator',
  defaultMessage:
    ' Use at least {characterMinLength} with a {uppercase}, a {lowercase}, a {number} and a {symbol}.',
};

export const defaultRules: Rule[] = [
  {
    validate: (password: string): boolean =>
      isLength(password, { min: MIN_CHARACTER_LENGTH }),
    placeholderKey: 'characterMinLength',
    messageProps: {
      id: 'customer.form.password.characterMinLength',
      defaultMessage:
        '{characterMinLength, plural, one {1 character} other {{characterMinLength} characters}}.',
      values: {
        characterMinLength: MIN_CHARACTER_LENGTH,
      },
    },
  },
  {
    validate: (password: string): boolean => HAS_UPPER_REGEX.test(password),
    placeholderKey: 'uppercase',
    messageProps: {
      id: 'customer.form.password.uppercase',
      defaultMessage: 'uppercase',
    },
  },
  {
    validate: (password: string): boolean => HAS_LOWER_REGEX.test(password),
    placeholderKey: 'lowercase',
    messageProps: {
      id: 'customer.form.password.lowercase',
      defaultMessage: 'lowercase',
    },
  },
  {
    validate: (password: string): boolean => HAS_NUMBER_REGEX.test(password),
    placeholderKey: 'number',
    messageProps: {
      id: 'customer.form.password.number',
      defaultMessage: 'number',
    },
  },
  {
    validate: (password: string): boolean => HAS_SPECIAL_REGEX.test(password),
    placeholderKey: 'symbol',
    messageProps: {
      id: 'customer.form.password.symbol',
      defaultMessage: 'symbol',
    },
  },
];
