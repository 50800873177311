import get from 'lodash/get';
import { Animated, ColorValue, Platform } from 'react-native';

import { Color, Theme, scaleUnitLess } from '../theme';
import { MaskProps } from './typings';

export type AnimatedTextStyle = {
  left: number;
  top: Animated.AnimatedInterpolation;
  fontSize: Animated.AnimatedInterpolation;
  color: Animated.AnimatedInterpolation;
};

export function animatedTextStyle(
  animatedIsFocused: Animated.Value,
  focused: boolean,
  theme?: Theme,
  placeholderTextColor?: ColorValue,
): AnimatedTextStyle {
  return {
    left: Platform.OS === 'web' ? scaleUnitLess(16) : 0,
    top: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange:
        Platform.OS === 'web' ? ['1em', '0.875em'] : [scaleUnitLess(18), 0],
    }),
    fontSize: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange:
        Platform.OS === 'web'
          ? ['1em', '0.625em']
          : [
              scaleUnitLess(16, { forFont: true }),
              scaleUnitLess(12, { forFont: true }),
            ],
    }),
    color: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange:
        Platform.OS === 'web'
          ? [
              placeholderTextColor || get(theme, `color.${Color.GRAY_300}`),

              get(theme, `color.${Color.GRAY_500}`),
            ]
          : [
              placeholderTextColor || get(theme, `color.${Color.GRAY_300}`),
              get(theme, `color.${focused ? Color.TERTIARY : Color.GRAY_500}`),
            ],
    }),
  };
}

export const cardSecurityCodeMaskProps: MaskProps = Object.freeze({
  type: 'custom',
  options: {
    mask: '9999',
    getRawValue(value: string): number {
      return parseInt(value, 10);
    },
  },
});

export const cardValidationDateMaskProps: MaskProps = Object.freeze({
  type: 'datetime',
  options: {
    format: 'MM/YY',
  },
});

export function moneyMaskProps(unit: string): MaskProps {
  return Object.freeze({
    type: 'money',
    options: {
      precision: 2,
      delimiter: ' ',
      unit: '',
      suffixUnit: unit,
    },
  });
}
