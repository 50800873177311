import React, { FC, ReactElement } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { defaultMessageProps, defaultRules } from './defaultStrengthConfig';
import { Label, StyledFragment } from './styledComponents';
import { Rule } from './typings';

export type Props = {
  text?: string;
  touched?: boolean;
  messageProps?: MessageDescriptor;
  rules?: Rule[];
};

type ValueDictionary = { [key: string]: JSX.Element | string };

const StrengthValidator: FC<Props> = ({
  text = '',
  touched = false,
  messageProps = defaultMessageProps,
  rules = defaultRules,
}: Props): ReactElement => {
  const valuesDictionary: ValueDictionary = {};

  rules.forEach((rule: Rule): void => {
    if (!rule.disabled) {
      valuesDictionary[rule.placeholderKey] = (
        <StyledFragment valid={touched ? rule.validate(text) : undefined}>
          <FormattedMessage {...rule.messageProps} />
        </StyledFragment>
      );
    } else {
      valuesDictionary[rule.placeholderKey] = '';
    }
  });

  return (
    <Label>
      <FormattedMessage {...messageProps} values={valuesDictionary} />
    </Label>
  );
};

export * from './typings';
export default StrengthValidator;
