import React, { FC, ReactElement, Ref, forwardRef, useState } from 'react';
import { TextInput as NativeTextInput } from 'react-native';

import StrengthValidator, {
  Props as StrengthValidatorProps,
} from '../StrengthValidator';
import Symbol, { SymbolKey } from '../Symbol';
import TextInput, { Props as TextInputProps } from '../TextInput';
import {
  Container,
  FloatingSymbolContainer,
  SymbolTouchableContainer,
} from './styledComponents';

export interface Props extends TextInputProps {
  strengthValidatorProps?: StrengthValidatorProps;
  withStrengthValidator?: boolean;
  touched?: boolean;
}

const PasswordInput: FC<Props> = ({
  touched = false,
  withStrengthValidator,
  strengthValidatorProps,
  ...textInputProps
}: Props): ReactElement => {
  const [inputValue, setInputValue] = useState(textInputProps.value || '');
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  if (withStrengthValidator) {
    textInputProps.helper = (
      <StrengthValidator
        {...strengthValidatorProps}
        text={inputValue}
        touched={touched}
      />
    );
  }

  return (
    <Container
      width={textInputProps.width}
      fullWidth={textInputProps.fullWidth}
    >
      <FloatingSymbolContainer>
        <SymbolTouchableContainer
          testID="symbol-touchable"
          onPress={(): void => setSecureTextEntry(!secureTextEntry)}
        >
          <Symbol
            scale={1}
            tabIndex="-1"
            symbolKey={
              `accessibility/eye-${
                secureTextEntry ? 'open' : 'close'
              }` as SymbolKey
            }
          />
        </SymbolTouchableContainer>
      </FloatingSymbolContainer>

      <TextInput
        secureTextEntry={secureTextEntry}
        {...textInputProps}
        ref={textInputProps.innerReference}
        onChangeText={(text: string): void => {
          setInputValue(text);
          if (textInputProps.onChangeText) textInputProps.onChangeText(text);
        }}
      />
    </Container>
  );
};

export default forwardRef(
  (props: Props, ref?: Ref<NativeTextInput>): ReactElement => (
    <PasswordInput {...props} innerReference={ref} />
  ),
);
